<template>
  <div>
    <ValidationObserver v-slot="{ invalid }">
      <div class="router-view-container">
        <div class="flex items-center space-x-4 mb-5">
          <vs-button @click="$router.go(-1)" border size="large" class="flex-shrink-0">
            <svg-icon icon-class="arrow_left" className="primary"></svg-icon>
          </vs-button>

          <h1 class="page_title">{{ $route.meta.title }}</h1>
        </div>
        <main class="space-y-10">
          <!-- Facebook Messenger -->
          <section class="bg-white rounded-2xl p-4 space-y-4">
            <div class="flex items-center justify-between">
              <h1 class="bigTitle">Facebook Messenger</h1>

              <vs-switch color="success" v-model="messengerActive" :disabled="!fid">
                <template #off>
                  <span class="text-sm">已停用</span>
                </template>
                <template #on>
                  <span class="text-sm">啟用中</span>
                </template>
              </vs-switch>
            </div>
            <div class="w-full">
              <h2 class="title">Facebook 粉絲專頁編號</h2>
              <div class="relative">
                <ValidationProvider
                  name="Facebook 粉絲專頁編號"
                  :rules="`${messengerActive ? 'required' : ''} | max:100`"
                  v-slot="{ errors }"
                >
                  <vs-input v-model="fid" />
                  <errorDiv>{{ errors[0] }}</errorDiv>
                </ValidationProvider>
              </div>
            </div>
          </section>
          <!-- Facebook 快速登入 -->
          <section class="bg-white rounded-2xl p-4 space-y-4">
            <div class="flex items-center justify-between">
              <h1 class="bigTitle">Facebook 快速登入</h1>

              <vs-switch color="success" v-model="loginActive" :disabled="!fb_appid">
                <template #off>
                  <span class="text-sm">已停用</span>
                </template>
                <template #on>
                  <span class="text-sm">啟用中</span>
                </template>
              </vs-switch>
            </div>
            <div class="flex justify-around space-x-4">
              <div class="w-full">
                <h2 class="title">應用程式編號</h2>
                <div class="relative">
                  <ValidationProvider name="應用程式編號" :rules="`${loginActive ? 'required' : ''} | max:100`" v-slot="{ errors }">
                    <vs-input v-model="fb_appid" />
                    <errorDiv>{{ errors[0] }}</errorDiv>
                  </ValidationProvider>
                </div>
              </div>
            </div>
            <!-- 教學文件 -->
            <div class="center">
              <vs-button flat @click="teachingActive1 = !teachingActive1"> 教學文件 </vs-button>
              <vs-dialog full-screen scroll overflow-hidden v-model="teachingActive1">
                <template #header>
                  <h3 class="text-lg">Facebook快速登入設定</h3>
                </template>
                <div class="con-content space-y-2 text-sm">
                  <h5 class="text-base">步驟一</h5>
                  <p>
                    請先至Facebook 應用程式申請帳號 <br />
                    <br />
                    建立應用程式
                  </p>
                  <img src="@/assets/images/teaching/fb05.jpg" alt="" />
                  <br />
                  <h5 class="text-base">步驟二</h5>

                  <p>選擇消費者</p>
                  <img src="@/assets/images/teaching/fb06.jpg" alt="" />

                  <br />
                  <h5 class="text-base">步驟三</h5>

                  <p>填寫所需資料</p>
                  <img src="@/assets/images/teaching/fb07.jpg" alt="" />

                  <br />
                  <h5 class="text-base">步驟四</h5>

                  <p>設定->基本資料->填寫所需資料</p>
                  <img src="@/assets/images/teaching/fb08.jpg" alt="" />

                  <br />
                  <h5 class="text-base">步驟五</h5>

                  <p>
                    主控版->facebook 登入設定 <br /><br />
                    記得將程式模式開發中勾選為上線
                  </p>
                  <img src="@/assets/images/teaching/fb09.jpg" alt="" />

                  <br />
                  <h5 class="text-base">步驟六</h5>

                  <p>選擇網站</p>
                  <img src="@/assets/images/teaching/fb10.jpg" alt="" />

                  <br />
                  <h5 class="text-base">步驟七</h5>

                  <p>填寫網址即可</p>
                  <img src="@/assets/images/teaching/fb11.jpg" alt="" />

                  <br />
                  <h5 class="text-base">步驟八</h5>

                  <p>facebook 登入->設定->填寫網站網址</p>
                  <img src="@/assets/images/teaching/fb12.jpg" alt="" />

                  <br />
                  <h5 class="text-base">步驟九</h5>

                  <p>應用程式審查->權限和功能->取得進階存取權限</p>
                  <img src="@/assets/images/teaching/fb13.jpg" alt="" />

                  <br />
                  <h5 class="text-base">步驟十</h5>

                  <p>設定->基本資料->找到應用程式編號和密鑰->填入網站後台</p>
                  <img src="@/assets/images/teaching/fb14.jpg" alt="" />

                  <br />
                </div>
              </vs-dialog>
            </div>
            <!-- 教學文件 -->
          </section>

          <!-- Facebook 像素 -->
          <section class="bg-white rounded-2xl p-4 space-y-4">
            <div class="flex items-center justify-between">
              <h1 class="bigTitle">Facebook 像素</h1>

              <vs-switch color="success" v-model="pixelActive" :disabled="!(fb_pixelid || fb_pixelapi)">
                <template #off>
                  <span class="text-sm">已停用</span>
                </template>
                <template #on>
                  <span class="text-sm">啟用中</span>
                </template>
              </vs-switch>
            </div>

            <div class="flex justify-around space-x-4">
              <div class="w-full">
                <h2 class="title">像素編號</h2>
                <div class="relative">
                  <ValidationProvider name="像素編號" :rules="`${pixelActive ? 'required' : ''} | max:100`" v-slot="{ errors }">
                    <vs-input v-model="fb_pixelid" />
                    <errorDiv>{{ errors[0] }}</errorDiv>
                  </ValidationProvider>
                </div>
              </div>
              <div class="w-full" style="display: none">
                <h2 class="title">轉換 API 存取</h2>
                <div class="relative">
                  <ValidationProvider name="轉換 API 存取" :rules="`${pixelActive ? 'required' : ''} | max:300`" v-slot="{ errors }">
                    <vs-input v-model="fb_pixelapi" />
                    <errorDiv>{{ errors[0] }}</errorDiv>
                  </ValidationProvider>
                </div>
              </div>
            </div>
            <!-- 教學文件 -->
            <div class="center">
              <vs-button flat @click="teachingActive2 = !teachingActive2"> 教學文件 </vs-button>
              <vs-dialog full-screen scroll overflow-hidden v-model="teachingActive2">
                <template #header>
                  <h3 class="text-lg">Facebook 追蹤設定</h3>
                </template>
                <div class="con-content space-y-2 text-sm">
                  <p>設定facebook像素</p>
                  <h5 class="text-base">步驟一</h5>
                  <p>前往 Facebook 事件管理工具->點選左邊綠色+符號->連結資料來源</p>
                  <img src="@/assets/images/teaching/fb15.jpg" alt="" />
                  <br />
                  <h5 class="text-base">步驟二</h5>
                  <p>選擇網站</p>
                  <img src="@/assets/images/teaching/fb16.jpg" alt="" />
                  <br />
                  <h5 class="text-base">步驟三</h5>
                  <p>選擇轉換api</p>
                  <img src="@/assets/images/teaching/fb17.jpg" alt="" />
                  <br />
                  <h5 class="text-base">步驟四</h5>
                  <p>填寫所需資料</p>
                  <img src="@/assets/images/teaching/fb18.jpg" alt="" />
                  <br />
                  <h5 class="text-base">步驟五</h5>
                  <p>填寫所需資料</p>
                  <img src="@/assets/images/teaching/fb18.jpg" alt="" />
                  <br />
                  <h5 class="text-base">步驟六</h5>
                  <p>資料來源->設定->找到像素編號->填入網站後台</p>
                  <img src="@/assets/images/teaching/fb19.jpg" alt="" />
                  <br />
                  <p>設定API轉換</p>
                  <h5 class="text-base">步驟一</h5>
                  <p>必須先擁有企業管理平台管理員身分才可以做此操作</p>
                  <p>資料來源->設定->轉換API->產生存取權杖->填入網站後台</p>
                  <img src="@/assets/images/teaching/fb21.jpg" alt="" />
                  <br />

                  <br />
                </div>
              </vs-dialog>
            </div>
            <!-- 教學文件 -->
          </section>

          <hr />
          <div class="flex justify-end">
            <vs-button size="large" ref="saveData" :disabled="invalid" @click="saveData(invalid)"
              ><span class="font-medium">儲存</span></vs-button
            >
          </div>
        </main>
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loginActive: false,
      pixelActive: false,
      messengerActive: false,
      fb_appid: '',
      fb_pixelid: '',
      fb_pixelapi: '',
      fid: '',
      teachingActive1: false,
      teachingActive2: false,
    }
  },
  created() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.$axios({
        url: 'front/set/app/getFacebookData.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
        },
      }).then((res) => {
        if (res.data.Success) {
          let data = res.data.Data

          this.fb_login = data.fb_login
          this.fb_appid = data.fb_appid
          this.fb_pixel = data.fb_pixel
          this.fb_pixelid = data.fb_pixelid
          this.fb_pixelapi = data.fb_pixelapi
          this.fid = data.fid
          this.ofservice = data.ofservice

          data.fb_login === 1 ? (this.loginActive = true) : (this.loginActive = false)
          data.fb_pixel === 1 ? (this.pixelActive = true) : (this.pixelActive = false)
          data.ofservice === 1 ? (this.messengerActive = true) : (this.messengerActive = false)
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
        }
      })
    },
    saveData(invalid) {
      // 如果驗證未通過，return
      if (invalid) return

      const loading = this.$vs.loading({
        target: this.$refs.saveData,
        background: 'primary',
        scale: '0.6',
        opacity: 1,
        color: '#fff',
      })

      this.$axios({
        url: 'front/set/app/uFacebookData.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
          fb_login: this.fb_login,
          fb_appid: this.fb_appid,
          fb_pixel: this.fb_pixel,
          fb_pixelid: this.fb_pixelid,
          fb_pixelapi: this.fb_pixelapi,
          fid: this.fid,
          ofservice: this.ofservice,
        },
      }).then((res) => {
        if (res.data.Success) {
          // 關閉按鈕loading
          loading.close()
          this.$myVS.openNoti(res.data.Message, '', undefined)
          this.loadData()
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
          loading.close()
        }
      })
    },
  },
  computed: {
    fb_login: {
      get() {
        return this.loginActive ? 1 : 0
      },
      //沒加set會報錯
      set() {},
    },
    fb_pixel: {
      get() {
        return this.pixelActive ? 1 : 0
      },
      //沒加set會報錯
      set() {},
    },
    ofservice: {
      get() {
        return this.messengerActive ? 1 : 0
      },
      //沒加set會報錯
      set() {},
    },
  },
  watch: {
    fid: function () {
      if (this.fid === '') {
        this.messengerActive = false
      }
    },
    fb_appid: function () {
      if (this.fb_appid === '') {
        this.loginActive = false
      }
    },
    fb_pixelid: function () {
      if (this.fb_pixelid === '' && this.fb_pixelapi === '') {
        this.pixelActive = false
      }
    },
    fb_pixelapi: function () {
      if (this.fb_pixelid === '' && this.fb_pixelapi === '') {
        this.pixelActive = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.title {
  @apply text-sm font-medium text-gray-text_dark mb-1;
}

.bigTitle {
  @apply text-base font-medium text-gray-text_dark;
}
</style>
